<template>
    <div class="wrap-plan">
        <CRow>
            <CCard class="cardERC-form">
                <CCardBody>
                    <ValidationObserver ref="observer"  @submit.prevent="submitAction">
                        <CForm>
                            <div class="cont-stak">
                                <div class="info-stak">
                                <label class="tit-plan">{{$t("EDIT_PLAN_STAKING_PAGE_TITLE_STAKING_INFORMATION")}}</label>
                                <ValidationProvider name="platform" rules="required">
                                    <div class="select-wrap form-group">
                                        <label>{{$t("EDIT_PLAN_STAKING_PAGE_LABEL_PLATFORM")}}</label>
                                        <select class="form-control role-select" v-model="platform.platform" disabled>
                                            <option
                                                    v-for="option in options"
                                                    v-bind:value="option.symbol"
                                                    :key="option.symbol"
                                            >{{ option.symbol }}</option>
                                        </select>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                    <div>
                                        <CInput
                                                :label="$t('EDIT_PLAN_STAKING_PAGE_LABEL_TOKEN_NAME')"
                                                :placeholder="$t('EDIT_PLAN_STAKING_PAGE_PLACEHOLDER_TOKEN_NAME')"
                                                horizontal
                                                v-model="platform.name"
                                                class="form-group--custom"
                                                disabled
                                        />
                                        <CRow>
                                            <CCol :sm="{offset:'3'}"></CCol>
                                            <CCol :sm="9">
                                                <p class="error-msg">{{errors[0]}}</p>
                                            </CCol>
                                        </CRow>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="symbol" rules="required" v-slot="{ errors }">
                                    <div>
                                        <CInput
                                                :label="$t('EDIT_PLAN_STAKING_PAGE_LABEL_TOKEN_CODE')"
                                                :placeholder="$t('EDIT_PLAN_STAKING_PAGE_PLACEHOLDER_TOKEN_CODE')"
                                                horizontal
                                                v-model="platform.symbol"
                                                class="form-group--custom"
                                                disabled
                                        />
                                        <CRow>
                                            <CCol :sm="{offset:'3'}"></CCol>
                                            <CCol :sm="9">
                                                <p class="error-msg">{{errors[0]}}</p>
                                            </CCol>
                                        </CRow>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="sc_token_address" rules="required" v-slot="{ errors }">
                                    <div>
                                        <CInput
                                                :label="$t('EDIT_PLAN_STAKING_PAGE_LABEL_TOKEN_ADDRESS')"
                                                :placeholder="$t('EDIT_PLAN_STAKING_PAGE_PLACEHOLDER_TOKEN_ADDRESS')"
                                                horizontal
                                                v-model="platform.sc_token_address"
                                                class="form-group--custom"
                                                disabled
                                        />
                                        <CRow>
                                            <CCol :sm="{offset:'3'}"></CCol>
                                            <CCol :sm="9">
                                                <p class="error-msg">{{errors[0]}}</p>
                                            </CCol>
                                        </CRow>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="sc_lookup_addr">
                                    <div class="form-group form-group--custom  form-look">
                                        <label>{{$t("EDIT_PLAN_STAKING_PAGE_LABEL_LOCKING_CONTRACT")}}</label>
                                        {{platform.sc_lookup_addr}}
                                    </div>
                                </ValidationProvider>
                            </div>
                            </div>
                            
                            <div class="info-plan">
                                <label class="tit-plan">{{$t('EDIT_STAKING_PLAN_PAGE_TITLE_PLAN_INFORMATION')}}</label>
                                <div class="left-plan">
                                    <ValidationProvider :name="$t('EDIT_STAKING_PLAN_PAGE_FIELD_NAME_VALIDATE_NAME')" rules="required|max:20" v-slot="{ errors }">
                                        <div>
                                            <CInput
                                                    :label="$t('EDIT_PLAN_STAKING_PAGE_LABEL_NAME')"
                                                    :placeholder="$t('EDIT_PLAN_STAKING_PAGE_PLACEHOLDER_NAME')"
                                                    v-model="plan.name"
                                                    horizontal
                                                    class="form-group--custom"
                                            />
                                            <CRow>
                                                <CCol :sm="{offset:'3'}"></CCol>
                                                <CCol :sm="9" class="wrap-error">
                                                    <p class="error-msg">{{errors[0]}}</p>
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider :name="$t('EDIT_STAKING_PLAN_PAGE_FIELD_NAME_VALIDATE_DURATION')" rules="required" v-slot="{ errors }">
                                        <div>
                                            <CInput
                                                    :label="$t('EDIT_PLAN_STAKING_PAGE_LABEL_DURATION')"
                                                    :placeholder="$t('EDIT_PLAN_STAKING_PAGE_PLACEHOLDER_DURATION')"
                                                    v-model="plan.duration"
                                                    horizontal
                                                    class="form-group--custom"
                                                    disabled
                                            />
                                            <CRow>
                                                <CCol :sm="{offset:'3'}"></CCol>
                                                <CCol :sm="9">
                                                    <p class="error-msg">{{errors[0]}}</p>
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </ValidationProvider>
                                    
                                    <ValidationProvider :name="$t('EDIT_STAKING_PLAN_PAGE_FIELD_NAME_VALIDATE_REWARD_PERCENTAGE')" rules="required" v-slot="{ errors }">
                                        <div>
                                            <CInput
                                                    :label="$t('EDIT_PLAN_STAKING_PAGE_LABEL_REWARD_PERCENTAGE')"
                                                    :placeholder="$t('EDIT_PLAN_STAKING_PAGE_PLACEHOLDER_REWARD_PERCENTAGE')"
                                                    v-model="plan.reward_percentage"
                                                    horizontal
                                                    class="form-group--custom"
                                                    disabled
                                            />
                                            <CRow>
                                                <CCol :sm="{offset:'3'}"></CCol>
                                                <CCol :sm="9">
                                                    <p class="error-msg">{{errors[0]}}</p>
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="right-plan">
                                    <ValidationProvider :name="$t('EDIT_STAKING_PLAN_PAGE_FIELD_NAME_VALIDATE_DURATION_TYPE')">
                                        <div class="select-wrap form-group unit">
                                            <CSelect
                                                    :label="$t('EDIT_PLAN_STAKING_PAGE_LABEL_UNIT')"
                                                    horizontal
                                                    disabled
                                                    :value="plan.duration_type"
                                                    :options="timeUnits"
                                            />
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider :name="$t('EDIT_STAKING_PLAN_PAGE_FIELD_NAME_VALIDATE_STATUS')" rules="required">
                                        <div class="select-wrap form-group">
                                            <label>{{$t('EDIT_PLAN_STAKING_PAGE_LABEL_STATUS')}}</label>
                                            <select class="form-control role-select" v-model="plan.status">
                                                <option
                                                        v-for="item in status"
                                                        v-bind:value="item"
                                                        :key="item">{{$t(`EDIT_PLAN_STAKING_PAGE_OPTION_SELECT_${renderStatus(item)}`)}}</option>
                                            </select>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="btn-pay">
                                    <CButton type="submit">{{$t("EDIT_PLAN_STAKING_PAGE_BUTTON_SAVE")}}</CButton>
                                </div>
                            </div>
                            
                        </CForm>
                    </ValidationObserver>
                </CCardBody>
            </CCard>
        </CRow>
    </div>
</template>
<script>
    import Draggable from "vuedraggable";
    import endpoints from "@/constants/endpoints";
    import { mapState, mapActions } from "vuex";
    export default {
        components: {
            Draggable
        },
        data() {
            return {
                platforms: [],
                options: [],
                status:[
                    1,
                    0,
                ],
                units:[]
            };
        },
        computed: {
            ...mapState({
                platform: state => state.platform.selectedPlatform,
                timeUnits: state => state.platform.timeUnits,
                plan: state => state.platform.selectedPlan,
            }),
        },
        async mounted() {
            await this.getPlatforms();
            await this.fetchTimeUnit();
        },
        methods: {
            ...mapActions([
                "AddPlan",
                "fetchTimeUnit",
            ]),
            async getPlatforms() {
                let result = await this.$http.get(
                    endpoints.getStakingPlatformConfig,
                );
                if (result.data) {
                    this.options = result.data
                }
            },
            async submitAction() {
                const isValid = await this.$refs.observer.validate();
                if (!isValid) return;
                let data;
                    try{
                        data = {
                            name: this.plan.name,
                            status: this.plan.status
                        }
                        const { staking_platform_id, id } = this.plan;
                        const result = await this.$http.put(endpoints.updateStakingPlan(staking_platform_id, id), data);
                        if (result.data) {
                            this.$notify({
                                group: "notifications",
                                type: "success",
                                title: '',
                                text: this.$t("EDIT_PLAN_STAKING_PAGE_TEXT_NOTIFY_SUCCESS")
                            });
                            return result.data,
                            this.$router.back();
                        }
                    } catch (err) {
                        if(err){
                            console.log(err);
                            this.$notify({
                                group: 'notifications',
                                type: 'error',
                                title: '',
                                text: this.$t('EDIT_PLAN_STAKING_PAGE_TEXT_NOTIFY_ERROR')
                            });
                            // this.$router.back();
                        }
                    }
            },
            renderStatus(item) {
                // if (item === -2) {
                //     return 'Hidden'
                // }
                if (item === 0) {
                    return 'DEACTIVE'
                }
                // if (item === 0) {
                //     return 'Coming Soon'
                // }
                if (item === 1) {
                    return 'ACTIVE'
                }
            }
        },
    };
</script>
<style lang="scss">
    .wrap-plan{     
        padding: 15px 40px;
        .cardERC-form{
            background-color: unset;
            border: none;
        }
        .card{
            width: 75%;
            @media screen and (max-width: 1200px){
                width: 100%;
            }
            .card-body{
                padding: 0
            }
            .tit-plan{
                color: #657187;
                font-size: 18px;
                font-weight: 600;
                line-height: 34px;
                display: block;
            }
            .form-look{
                    label{
                        width: 25%
                    }
                }
            // .num-pay{
            //     input{
            //         width: 25% !important;
            //     }
            // }
            .cont-stak {
                width: 100%;
                background: #fff;
                .info-stak{
                padding: 25px 10px 10px 25px;
                
                width:63%;
                // .form-group{
                //     margin-bottom: 0;
                // }
                @media screen and (max-width: 1200px){
                    padding: 25px;
                    width: 100%;
                    }
            }
            }
            
            .info-plan{
                margin-top: 25px;
                padding: 25px;
                background: #fff;
                .left-plan{
                    width: 63%;
                    float: left;
                    @media screen and (max-width: 1250px){
                        width: 60%;
                    }
                     @media screen and (max-width: 900px){
                        width: 100%;
                    }
                }
                .right-plan{
                    width: 37%;
                    float: right;
                    padding-left: 30px;
                    @media screen and (max-width: 1250px){
                        width: 40%;
                    }
                    @media screen and (max-width: 900px){
                        width: 100%;
                        padding-left: 0;
                    }
                }
            }
            .select-wrap{
                padding-right: 10px;
                margin-bottom: 30px;
                label{
                    width: 33%
                }
                select{
                    margin-left: 5px;
                }
                &.unit{
                    margin-bottom: 15px;
                    .form-group{
                        .col-sm-9{
                            // padding-left: 0;
                            select{
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
            .form-group{
                display: flex;
                align-items: center;
                width: 100%;
                
                label{
                    text-align: right;
                    /* padding-right: 15px; */
                    padding: 0 15px 0 0;
                    line-height: 20px;
                    color: #3e4b65;
                    font-size: 14px;
                    align-self: center;
                    font-weight: 500;
                    letter-spacing: .5px;
                    margin-bottom: 0;
                    @media screen and (max-width: 600px){
                        text-align: left;
                        width: 100%;
                    }
                }
                // input,select{
                //     width: 90%;
                // }
                // select{
                //     width: 90%;
                // }
            
            }
            .btn-pay{
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                // padding-right: 50px;

                button[type="submit"]{
                    min-width: 146px;
                    height: 36px;
                    border-radius: 4px;
                    background-color: #3b4b7e;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: .5px;
                    border: none;
                    outline: none!important;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    text-align: center
                }
            }
            
        }
        .wrap-error{
            padding-left: 10px;
            .error-msg{
                color: red;
                margin-top: -10px;
                margin-bottom: 26px;
            }   
        }
    }
</style>