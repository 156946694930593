var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap-plan"},[_c('CRow',[_c('CCard',{staticClass:"cardERC-form"},[_c('CCardBody',[_c('ValidationObserver',{ref:"observer",on:{"submit":function($event){$event.preventDefault();return _vm.submitAction($event)}}},[_c('CForm',[_c('div',{staticClass:"cont-stak"},[_c('div',{staticClass:"info-stak"},[_c('label',{staticClass:"tit-plan"},[_vm._v(_vm._s(_vm.$t("EDIT_PLAN_STAKING_PAGE_TITLE_STAKING_INFORMATION")))]),_c('ValidationProvider',{attrs:{"name":"platform","rules":"required"}},[_c('div',{staticClass:"select-wrap form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("EDIT_PLAN_STAKING_PAGE_LABEL_PLATFORM")))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.platform.platform),expression:"platform.platform"}],staticClass:"form-control role-select",attrs:{"disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.platform, "platform", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.options),function(option){return _c('option',{key:option.symbol,domProps:{"value":option.symbol}},[_vm._v(_vm._s(option.symbol))])}),0)])]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('EDIT_PLAN_STAKING_PAGE_LABEL_TOKEN_NAME'),"placeholder":_vm.$t('EDIT_PLAN_STAKING_PAGE_PLACEHOLDER_TOKEN_NAME'),"horizontal":"","disabled":""},model:{value:(_vm.platform.name),callback:function ($$v) {_vm.$set(_vm.platform, "name", $$v)},expression:"platform.name"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"symbol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('EDIT_PLAN_STAKING_PAGE_LABEL_TOKEN_CODE'),"placeholder":_vm.$t('EDIT_PLAN_STAKING_PAGE_PLACEHOLDER_TOKEN_CODE'),"horizontal":"","disabled":""},model:{value:(_vm.platform.symbol),callback:function ($$v) {_vm.$set(_vm.platform, "symbol", $$v)},expression:"platform.symbol"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"sc_token_address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('EDIT_PLAN_STAKING_PAGE_LABEL_TOKEN_ADDRESS'),"placeholder":_vm.$t('EDIT_PLAN_STAKING_PAGE_PLACEHOLDER_TOKEN_ADDRESS'),"horizontal":"","disabled":""},model:{value:(_vm.platform.sc_token_address),callback:function ($$v) {_vm.$set(_vm.platform, "sc_token_address", $$v)},expression:"platform.sc_token_address"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"sc_lookup_addr"}},[_c('div',{staticClass:"form-group form-group--custom  form-look"},[_c('label',[_vm._v(_vm._s(_vm.$t("EDIT_PLAN_STAKING_PAGE_LABEL_LOCKING_CONTRACT")))]),_vm._v(" "+_vm._s(_vm.platform.sc_lookup_addr)+" ")])])],1)]),_c('div',{staticClass:"info-plan"},[_c('label',{staticClass:"tit-plan"},[_vm._v(_vm._s(_vm.$t('EDIT_STAKING_PLAN_PAGE_TITLE_PLAN_INFORMATION')))]),_c('div',{staticClass:"left-plan"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('EDIT_STAKING_PLAN_PAGE_FIELD_NAME_VALIDATE_NAME'),"rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('EDIT_PLAN_STAKING_PAGE_LABEL_NAME'),"placeholder":_vm.$t('EDIT_PLAN_STAKING_PAGE_PLACEHOLDER_NAME'),"horizontal":""},model:{value:(_vm.plan.name),callback:function ($$v) {_vm.$set(_vm.plan, "name", $$v)},expression:"plan.name"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{staticClass:"wrap-error",attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('EDIT_STAKING_PLAN_PAGE_FIELD_NAME_VALIDATE_DURATION'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('EDIT_PLAN_STAKING_PAGE_LABEL_DURATION'),"placeholder":_vm.$t('EDIT_PLAN_STAKING_PAGE_PLACEHOLDER_DURATION'),"horizontal":"","disabled":""},model:{value:(_vm.plan.duration),callback:function ($$v) {_vm.$set(_vm.plan, "duration", $$v)},expression:"plan.duration"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('EDIT_STAKING_PLAN_PAGE_FIELD_NAME_VALIDATE_REWARD_PERCENTAGE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('EDIT_PLAN_STAKING_PAGE_LABEL_REWARD_PERCENTAGE'),"placeholder":_vm.$t('EDIT_PLAN_STAKING_PAGE_PLACEHOLDER_REWARD_PERCENTAGE'),"horizontal":"","disabled":""},model:{value:(_vm.plan.reward_percentage),callback:function ($$v) {_vm.$set(_vm.plan, "reward_percentage", $$v)},expression:"plan.reward_percentage"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])})],1),_c('div',{staticClass:"right-plan"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('EDIT_STAKING_PLAN_PAGE_FIELD_NAME_VALIDATE_DURATION_TYPE')}},[_c('div',{staticClass:"select-wrap form-group unit"},[_c('CSelect',{attrs:{"label":_vm.$t('EDIT_PLAN_STAKING_PAGE_LABEL_UNIT'),"horizontal":"","disabled":"","value":_vm.plan.duration_type,"options":_vm.timeUnits}})],1)]),_c('ValidationProvider',{attrs:{"name":_vm.$t('EDIT_STAKING_PLAN_PAGE_FIELD_NAME_VALIDATE_STATUS'),"rules":"required"}},[_c('div',{staticClass:"select-wrap form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t('EDIT_PLAN_STAKING_PAGE_LABEL_STATUS')))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.plan.status),expression:"plan.status"}],staticClass:"form-control role-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.plan, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.status),function(item){return _c('option',{key:item,domProps:{"value":item}},[_vm._v(_vm._s(_vm.$t(("EDIT_PLAN_STAKING_PAGE_OPTION_SELECT_" + (_vm.renderStatus(item))))))])}),0)])])],1),_c('div',{staticClass:"btn-pay"},[_c('CButton',{attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t("EDIT_PLAN_STAKING_PAGE_BUTTON_SAVE")))])],1)])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }